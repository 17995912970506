import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import React, { useEffect, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import moment, { Moment } from 'moment'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogActions from '@mui/material/DialogActions'
import { Card, CardContent, MenuItem } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { DropzoneArea } from 'mui-file-dropzone'
import b64ToBlob from 'b64-to-blob'
import fileSaver from 'file-saver'
import { useReactToPrint } from 'react-to-print'
import { IAppointmentQuoteProps } from './types'
import { validationSchema } from './validation'
import PrintableQuote from './PrintableQuote'
import { IQuotePayload } from '../../types/quotes'
import { IDestinationSimple } from '../../types/destinations'
import { IShipSimple } from '../../types/ships'
import {
  currencyOptions,
  celebrityStateroomOptions,
  royalStateroomOptions,
  silverSeaStateroomOptions,
  reasonOptions,
  bookingBrandData,
  guestCategoryData,
  bookingOriginsData,
} from '../../utils/constants'
import { customDropZone } from '../../styles/generic-styles'
import { getCurrencySymbolByName } from '../../utils/utils'

const QuoteForm: React.FC<IAppointmentQuoteProps> = ({
  isLoading,
  isLoadingDownloadAttachment,
  isOpen,
  onClose,
  onDownloadQuoteAttachment,
  onSubmit,
  onSubmitEditQuote,
  products,
  quote,
  room,
  vessels,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    resetField,
    watch,
    trigger,
  } = useForm<IQuotePayload>({
    defaultValues: {
      amount: quote?.amount ?? undefined,
      comment: quote?.comment ?? undefined,
      quoteBrand: quote?.quoteBrand,
      quoteType: quote?.quoteType || 'Book Now',
      guestCategory: quote?.guestCategory,
      guestOrigin: quote?.guestOrigin,
      destinationId: quote?.destinationId ?? undefined,
      file: quote?.file ?? undefined,
      groupShellNumber: quote?.groupShellNumber ?? undefined,
      hasAttachment: quote?.hasAttachment ?? false,
      nonRefundable: quote?.nonRefundable ?? undefined,
      noPerk: quote?.noPerk ?? undefined,
      allIncluded: quote?.allIncluded ?? undefined,
      quoteId: quote?.quoteId ?? undefined,
      sailDate: quote?.sailDate ? moment.utc(quote.sailDate).format('YYYY-MM-DD') : undefined,
      shipId: quote?.shipId ?? undefined,
      stateroomCategory: quote?.stateroomCategory ?? undefined,
      noBookReason: quote?.noBookReason ?? undefined,
    },
    resolver: yupResolver(validationSchema),
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'groupQuoteItems',
  })
  const [enableGroup, setEnableGroup] = useState(false)
  const [numberOfQuotes, setNumberOfQuotes] = useState<number>(0)
  const [sailDate, setSailDate] = useState<Moment | null>(
    quote?.sailDate ? moment.utc(quote.sailDate) : null,
  )
  const [productValue, setProductValue] = useState<IDestinationSimple | null>(
    products.find((product) => product.id === quote?.destinationId) ?? null,
  )
  const [vesselValue, setVesselValue] = useState<IShipSimple | null>(
    vessels.find((vessel) => vessel.id === quote?.shipId) ?? null,
  )
  const [hasAttachment, setHasAttachment] = useState<boolean>(quote?.hasAttachment ?? false)
  const [stateroomOptions, setStateroomOptions] = useState<string[]>([])

  const groupQuotesValue = useWatch({
    control,
    name: 'groupQuoteItems',
  })

  const quoteBrands = bookingBrandData.map((item) => ({ value: item, label: item }))
  const guestCategories = guestCategoryData.map((item) => ({ value: item, label: item }))
  const guestOrigin = bookingOriginsData.map((item) => ({ value: item, label: item }))

  const quoteType = watch('quoteType')
  const quoteBrand = watch('quoteBrand')

  const componentRef = useRef(null)
  const formData = {
    amount: watch('amount'),
    cabins: groupQuotesValue,
    comment: watch('comment'),
    currency: getCurrencySymbolByName(watch('currency')),
    enableGroup,
    nonRefundable: watch('nonRefundable'),
    noPerk: watch('noPerk'),
    allIncluded: watch('allIncluded'),
    numberOfQuotes,
    exclusiveOnboardCredit: watch('exclusiveOnboardCredit'),
    promotion: watch('promotion'),
    product: productValue?.name,
    sailDate,
    stateroomCategory: watch('stateroomCategory'),
    noBookReason: watch('noBookReason'),
    vesselValue: vesselValue?.name,
  }

  useEffect(() => {
    if (quoteBrand) {
      switch (quoteBrand) {
        case 'Royal':
          setStateroomOptions(royalStateroomOptions)
          break
        case 'Silversea':
          setStateroomOptions(silverSeaStateroomOptions)
          break
        default:
          setStateroomOptions(celebrityStateroomOptions)
          break
      }
    }
  }, [quoteBrand])

  const handlePrintToPDF = useReactToPrint({
    content: () => componentRef.current!,
    documentTitle: `${room ?? ''} ${formData.vesselValue} ${
      formData.sailDate ? formData.sailDate.format('MM-DD-YYYY') : ''
    } Quote`,
  })

  const handlePrint = async () => {
    const formIsValid = await trigger()
    if (formIsValid) {
      handlePrintToPDF()
    }
  }

  const numberOfQuotesOptions = Array(100)
    .fill(1)
    .map((item, index) => ({
      label: `${item + index}`,
      value: item + index,
    }))

  const updateNumberOfQuotes = (value: number) => {
    if (value > numberOfQuotes) {
      for (let index = 0; index < value - numberOfQuotes; index += 1) {
        append({
          amount: 0,
          stateroomCategory: '',
        })
      }
    } else if (value < numberOfQuotes) {
      for (let index = numberOfQuotes; index >= value; index -= 1) {
        remove(index)
      }
    } else if (!value) {
      remove()
    }
    setNumberOfQuotes(value ?? 0)
  }

  const removeAttachment = () => {
    setHasAttachment(false)
    setValue('hasAttachment', false)
  }

  const onClickDownloadAttachment = async () => {
    if (onDownloadQuoteAttachment) {
      const responseAttachment = await onDownloadQuoteAttachment({
        quoteId: quote?.quoteId,
        groupShellNumber: quote?.groupShellNumber,
      })

      if (responseAttachment?.success) {
        const blob = b64ToBlob(responseAttachment.zipAsBase64, 'application/zip')
        fileSaver.saveAs(blob, `attachment-quote-${quote?.quoteId}.zip`)
      }
    }
  }

  const resetSomeFields = () => {
    resetField('amount')
    resetField('stateroomCategory')
    resetField('promotion')
    resetField('exclusiveOnboardCredit')
    resetField('currency')
    resetField('noBookReason')
    resetField('nonRefundable')
    resetField('noPerk')
    resetField('allIncluded')
    resetField('comment')
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} maxWidth="md">
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit((data) => {
          if (!quote) {
            onSubmit(data)
          } else {
            onSubmitEditQuote!(data)
          }
        })}>
        <DialogContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{quote ? 'Edit Quote' : 'Add a Quote'}</Typography>
            </Grid>
            <Grid item>
              <IconButton sx={{ padding: 0 }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{ margin: '1rem 0' }} />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="quoteType"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="quoteType"
                        label="Quote Type"
                        type="text"
                        defaultValue=""
                        error={!!errors.quoteType}
                        helperText={errors?.quoteType?.message as string}>
                        <MenuItem key="bookNow" value="Book Now">
                          Book Now
                        </MenuItem>
                        <MenuItem key="bookLater" value="Book Later">
                          Book Later
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
              {quoteType === 'Book Now' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="quoteBrand"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          margin="normal"
                          fullWidth
                          select
                          id="quoteBrand"
                          label="Brand"
                          type="text"
                          defaultValue=""
                          error={!!errors.quoteBrand}
                          helperText={errors?.quoteBrand?.message as string}>
                          {quoteBrands.map((item) => (
                            <MenuItem key={`quote-brand-key-${item.value}`} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="guestCategory"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          fullWidth
                          select
                          margin="normal"
                          id="guestCategory"
                          label="Guest Category"
                          type="text"
                          defaultValue=""
                          error={!!errors.guestCategory}
                          helperText={errors?.guestCategory?.message as string}>
                          {guestCategories.map((cat) => (
                            <MenuItem key={`quote-brand-key-${cat.value}`} value={cat.value}>
                              {cat.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="guestOrigin"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          fullWidth
                          select
                          id="guestOrigin"
                          label="Guest Origin"
                          type="text"
                          defaultValue=""
                          error={!!errors.guestOrigin}
                          helperText={errors?.guestOrigin?.message as string}>
                          {guestOrigin.map((item) => (
                            <MenuItem key={`quote-origin-key-${item.value}`} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="destinationId"
                      render={({ field: { onChange, ...field } }) => (
                        <Autocomplete
                          options={products}
                          getOptionLabel={(product) => product.name}
                          onChange={(_, data) => {
                            onChange(data?.id)
                            setProductValue(data)
                          }}
                          renderOption={(props, option) => (
                            <Box {...props} component="li" key={option.id} value={option.id}>
                              {option.name}
                            </Box>
                          )}
                          value={productValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              label="Product"
                              error={!!errors.destinationId}
                              helperText={errors?.destinationId?.message as string}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="shipId"
                      render={({ field: { onChange, ...field } }) => (
                        <Autocomplete
                          options={vessels}
                          getOptionLabel={(vessel) => vessel.name}
                          onChange={(_, data) => {
                            onChange(data?.id)
                            setVesselValue(data)
                          }}
                          renderOption={(props, option) => (
                            <Box {...props} component="li" key={option.id} value={option.id}>
                              {option.name}
                            </Box>
                          )}
                          value={vesselValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              label="Vessel"
                              fullWidth
                              error={!!errors.shipId}
                              helperText={errors?.shipId?.message as string}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} item alignSelf="center">
                    <Controller
                      control={control}
                      name="sailDate"
                      render={({ field: { onChange, ...field } }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            {...field}
                            label="Sail Date"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            value={sailDate}
                            minDate={moment()}
                            onChange={(newValue) => {
                              setSailDate(newValue)
                              onChange(newValue?.format('YYYY-MM-DD'))
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  {!enableGroup && (
                    <>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="amount"
                          defaultValue={0}
                          render={({ field }) => (
                            <TextField
                              label="Amount"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              error={!!errors.amount}
                              helperText={errors?.amount?.message as string}
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} alignSelf="center">
                        <Controller
                          control={control}
                          name="stateroomCategory"
                          render={({ field: { onChange, value, ...field } }) => (
                            <Autocomplete
                              options={stateroomOptions}
                              onChange={(_, data) => onChange(data)}
                              value={
                                stateroomOptions.find((stateroom) => stateroom === value) ?? null
                              }
                              renderOption={(props, option) => (
                                <Box {...props} component="li" key={option} value={option}>
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  {...field}
                                  label="Stateroom Category"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="promotion"
                          defaultValue=""
                          render={({ field }) => (
                            <TextField label="Promotion" type="text" fullWidth {...field} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="exclusiveOnboardCredit"
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              label="Exclusive Onboard Credit"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              fullWidth
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} alignSelf="center">
                        <Controller
                          control={control}
                          name="currency"
                          render={({ field: { onChange, value, ...field } }) => (
                            <Autocomplete
                              options={currencyOptions}
                              onChange={(_, data) => onChange(data)}
                              renderOption={(props, option) => (
                                <Box {...props} component="li" key={option} value={option}>
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} {...field} label="Currency" fullWidth />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} alignSelf="center">
                        <Controller
                          control={control}
                          name="noBookReason"
                          render={({ field: { onChange, value, ...field } }) => (
                            <Autocomplete
                              options={reasonOptions}
                              onChange={(_, data) => onChange(data)}
                              value={reasonOptions.find((reason) => reason === value) ?? null}
                              renderOption={(props, option) => (
                                <Box {...props} component="li" key={option} value={option}>
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  {...field}
                                  label="Why guest isn't ready to book?"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <Stack>
                      <Typography>Additional information</Typography>
                      <Controller
                        control={control}
                        name="nonRefundable"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox checked={value ?? false} />}
                            label="Non-refundable"
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="noPerk"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox checked={value ?? false} />}
                            label="No Perk"
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="allIncluded"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox checked={value ?? false} />}
                            label="All Included"
                            {...field}
                          />
                        )}
                      />
                      {!quote && (
                        <FormControlLabel
                          control={<Checkbox checked={enableGroup} />}
                          onChange={(_, checked) => {
                            setEnableGroup(checked)
                            if (!checked) {
                              remove()
                            }
                            if (checked) {
                              setValue('amount', 0)
                            }
                          }}
                          label="Enable Group"
                          value={enableGroup}
                        />
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="comment"
                      render={({ field: { value, ...field } }) => (
                        <TextField
                          {...field}
                          value={value ?? ''}
                          fullWidth
                          multiline
                          maxRows={3}
                          minRows={3}
                          placeholder="Comment"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!hasAttachment ? (
                      <Controller
                        name="file"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          if (value && value.length > 0)
                            return (
                              <TextField
                                margin="normal"
                                fullWidth
                                label="Attachment"
                                type="text"
                                defaultValue={(value[0] as File).name}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => setValue('file', [])}>
                                        <HighlightOffIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )
                          return (
                            <Box sx={customDropZone}>
                              <Typography>Attachment</Typography>
                              <DropzoneArea
                                acceptedFiles={['image/jpeg,image/png,image/gif,image/jpg']}
                                dropzoneText="Drag and drop or click here to attach a file"
                                fileObjects={[]}
                                filesLimit={1}
                                initialFiles={value as (File | string)[]}
                                onChange={onChange}
                                showAlerts={false}
                              />
                            </Box>
                          )
                        }}
                      />
                    ) : (
                      <Box sx={customDropZone}>
                        <Typography>Attachment</Typography>
                        <LoadingButton
                          loading={isLoadingDownloadAttachment}
                          color="secondary"
                          onClick={onClickDownloadAttachment}>
                          Download Attachment
                        </LoadingButton>
                        <IconButton onClick={removeAttachment}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Grid>

                  {enableGroup && (
                    <>
                      <Divider sx={{ margin: '1rem 0' }} />
                      <Grid item xs={6}>
                        <Autocomplete
                          onChange={(_, value) => updateNumberOfQuotes(value?.value)}
                          options={numberOfQuotesOptions}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.label === value.label && option.value === value.value
                          }
                          renderOption={(props, option) => (
                            <Box {...props} component="li" key={option.value} value={option.value}>
                              {option.value}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Number of Cabins" fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} />
                    </>
                  )}
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="quoteBrand"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          margin="normal"
                          fullWidth
                          select
                          id="quoteBrand"
                          label="Brand"
                          type="text"
                          defaultValue=""
                          error={!!errors.quoteBrand}
                          helperText={errors?.quoteBrand?.message as string}>
                          {quoteBrands.map((item) => (
                            <MenuItem key={`quote-brand-key-${item.value}`} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="guestCategory"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          fullWidth
                          select
                          margin="normal"
                          id="GuestCategory"
                          label="Guest Category"
                          type="text"
                          defaultValue=""
                          error={!!errors.guestCategory}
                          helperText={errors?.guestCategory?.message as string}>
                          {guestCategories.map((cat) => (
                            <MenuItem key={`quote-brand-key-${cat.value}`} value={cat.value}>
                              {cat.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="guestOrigin"
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          fullWidth
                          select
                          id="guestOrigin"
                          label="Guest Origin"
                          type="text"
                          defaultValue=""
                          error={!!errors.guestOrigin}
                          helperText={errors?.guestOrigin?.message as string}>
                          {guestOrigin.map((item) => (
                            <MenuItem key={`quote-origin-key-${item.value}`} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} alignSelf="center">
                    <Controller
                      control={control}
                      name="noBookReason"
                      render={({ field: { onChange, value, ...field } }) => (
                        <Autocomplete
                          options={reasonOptions}
                          onChange={(_, data) => onChange(data)}
                          value={reasonOptions.find((reason) => reason === value) ?? null}
                          renderOption={(props, option) => (
                            <Box {...props} component="li" key={option} value={option}>
                              {option}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              label="Why guest isn't ready to book?"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="comment"
                      render={({ field: { value, ...field } }) => (
                        <TextField
                          {...field}
                          value={value ?? ''}
                          fullWidth
                          multiline
                          maxRows={3}
                          minRows={3}
                          placeholder="Comment"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}

              {enableGroup && (
                <Stack sx={{ marginTop: '1rem' }}>
                  <Divider sx={{ margin: '1rem 0' }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Quotes</Typography>
                    </Grid>
                    {fields.map((item, index) => (
                      <React.Fragment key={`group-quote-${item.id}`}>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={`groupQuoteItems.${index}.stateroomCategory`}
                            render={({ field: { onChange, value, ...field } }) => (
                              <Autocomplete
                                options={stateroomOptions}
                                onChange={(_, data) => onChange(data)}
                                value={
                                  stateroomOptions.find((stateroom) => stateroom === value) ?? null
                                }
                                renderOption={(props, option) => (
                                  <Box {...props} component="li" key={option} value={option}>
                                    {option}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    {...field}
                                    label="Stateroom Category"
                                    fullWidth
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={`groupQuoteItems.${index}.amount`}
                            render={({ field }) => (
                              <TextField
                                label="Amount"
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                }}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Stack>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Box style={{ display: 'none' }}>
            <PrintableQuote ref={componentRef} formData={formData} />
          </Box>
          <Stack sx={{ mb: '1.25rem' }} direction="row" alignItems="center" columnGap={1}>
            {!enableGroup && (
              <LoadingButton loading={isLoading} onClick={handlePrint} color="secondary">
                Print
              </LoadingButton>
            )}
            {!quote && (
              <Stack direction="row" alignItems="center" columnGap={1}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit((data) => {
                    onSubmit(data, true)
                    reset()
                    remove()
                    setSailDate(null)
                    setEnableGroup(false)
                    setNumberOfQuotes(0)
                  })}>
                  Save and Add New
                </LoadingButton>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit((data) => {
                    onSubmit(data, true)
                    resetSomeFields()
                    remove()
                    setEnableGroup(false)
                    setNumberOfQuotes(0)
                  })}>
                  Add another Cat
                </LoadingButton>
              </Stack>
            )}
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="success"
              sx={{ marginRight: 2 }}>
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default QuoteForm
