import { Box, Container, Paper, Stack, Tab } from '@mui/material'
import numeral from 'numeral'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Alert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import AgentStatsTable from './AgentStatsTable'
import CardStatsByAgentTable from './CardStats/StatsByAgentTable'
import CardStatsByGuestTable from './CardStats/StatsByGuestTable'
import BigNumber from './BigNumber'
import { useDashboard } from './hook'
import { EStatsTab } from './types'
import MonthYearSelector from './MonthYearSelector/index'
import { IGuestStats } from './CardStats/StatsByGuestTable/types'
import CruiseStatsTable from './CruiseStatsTableMonthly'
import { theme } from '../../styles/mui-theme'
import { IAgentResponse, IDailyResponse, IPortStatResponse, IStatsPayload } from '../../types/stats'
import GridSpinner from '../../components/GridSpinner'
import UpdateTargetForm from '../../components/UpdateTargetForm'
import Notify from '../../utils/notify'
import { ITargetMonthlyPayload, ITargetPayload } from '../../types/cruises'
import Wizard from '../../components/Wizard'
import { useCard } from '../Appointments/hook'
import { useAppSelector } from '../../redux/hooks'
import { EUserRole } from '../../types/users'
import { tabContainerStyle } from '../../styles/generic-styles'
import { IGenericResponse } from '../../types/generic'
import { EStatusCard } from '../../types/cards'

const Dashboard = () => {
  // Variable to check location and error messages by redirection
  const location = useLocation()
  const navigate = useNavigate()
  const { updateCardStatus, updateCardTransferredToFolio } = useCard()

  const isActiveCruise = !!useAppSelector((state) => state.auth.activeCruise)
  const cruiseActive = useAppSelector((state) => state.auth.activeCruise)
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const isAgent = loggedUser && loggedUser.role === EUserRole.AGENT

  const [selectedTab, setSelectedTab] = useState(EStatsTab.VOYAGE)

  const today = moment()
  const currentDate = { month: today.month() + 1, year: today.year() }
  const [selectedDate, setSelectedDate] = useState<IStatsPayload>(currentDate)
  const [selectedBox, setSelectedBox] = useState<string>('offered')
  const [cardStats, setCardStats] = useState<IGuestStats[]>([])

  const [isTargetOpen, setIsTargetOpen] = useState(false)
  const [isTargetLoading, setIsTargetLoading] = useState(false)

  const {
    statsData,
    monthlyStatsData,
    isLoading,
    updateTarget,
    lazyGetStats,
    lazyGetMonthlyStats,
    updateTargetMonthly,
  } = useDashboard()

  useEffect(() => {
    if (!isActiveCruise) return
    if (selectedTab === EStatsTab.MONTHLY) {
      lazyGetMonthlyStats(selectedDate)
      lazyGetStats(selectedDate)
    } else {
      lazyGetStats(undefined)
    }
  }, [isActiveCruise, selectedTab, selectedDate])

  const onTargetResult = () => {
    lazyGetStats()
    setIsTargetLoading(false)
    setIsTargetOpen(false)
  }

  const onAcceptTarget = async (target: ITargetPayload) => {
    setIsTargetLoading(true)
    await updateTarget(target, onTargetResult, onTargetResult)
  }

  const onTargetMonthlySaved = () => {
    lazyGetStats(selectedDate)
    setIsTargetLoading(false)
    setIsTargetOpen(false)
  }

  const onAcceptTargetMonthly = async ({ target }: ITargetPayload) => {
    setIsTargetLoading(true)

    const dateToQuery = selectedDate || currentDate

    const targetMonthly: ITargetMonthlyPayload = {
      month: dateToQuery.month,
      year: dateToQuery.year,
      target,
      shipId: cruiseActive?.ship.id!,
    }

    await updateTargetMonthly(targetMonthly, onTargetMonthlySaved)
  }

  useEffect(() => {
    if (location && location.state && location.state.errorMessage) {
      Notify.error(location.state.errorMessage)
      // Clean state to avoid display error message if user refresh page
      navigate(location.pathname, { replace: true })
    }
  }, [location])

  const onSelectBox = (box: string) => {
    setSelectedBox(box)

    let formattedCardsData =
      statsData?.cardStats?.map((item) => ({
        guestName: `${item.person_first_name} ${item.person_last_name}`,
        stateroom: item.stateroom_number,
        agentName: `${item.agent_first_name} ${item.agent_last_name}`,
        agentId: item.agent_id,
        portName: item.port_name,
        date: new Date(item.date),
        cardId: item.card_id,
        cardStatus: item.card_status,
        cardTransferredToFolio: item.transferred_to_folio_account,
      })) ?? []

    if (box === EStatusCard.APPROVED || box === EStatusCard.DENIED || box === EStatusCard.PENDING) {
      formattedCardsData = formattedCardsData.filter(
        (card) => card.cardStatus === box.toUpperCase(),
      )
    } else if (box === 'transferred') {
      formattedCardsData = formattedCardsData.filter((card) => card.cardTransferredToFolio)
    }

    setCardStats(formattedCardsData)
  }

  const formattedData =
    statsData?.stats?.map((item) => ({
      fullName: `${item.first_name} ${item.last_name}`,
      appointments: item.appointments,
      appointmentsUsaGuests: item.appointments_usa_guests,
      totalOverall: item.total_overall,
      sales: item.sales,
      conversion: item.conversion,
      quotes: item.quotes,
      userId: item.user_id,
      cards: item.cards,
      cardsApproved: item.cards_approved,
      cardsDenied: item.cards_denied,
      cardsPending: item.cards_pending,
      cardsTransferredToFolio: item.cards_transferred_to_folio,
      averageApptTime: item.avg_appointment_time,
      bookingsOffered: item.bookings_offered,
      bookingsConfirmed: item.bookings_confirmed,
      bookingsCanceled: item.bookings_cancelled,
      bookingSupplemental: item.bookings_supplemental,
      bookingSelfService: item.bookings_selfService,
      bookingOfficeNow: item.booking_office_now,
      bookingOfficeLater: item.booking_office_later,
      bookingRemoteNow: item.booking_remote_now,
      bookingRemoteLater: item.booking_remote_later,
      qualifiedAppointments: item.qualified_appointments,
      nonQualifiedAppointments: item.non_qualified_appointments,
      personalContribution: item.personal_contribution,
      agentStats: item.daily?.map((dailyData: IDailyResponse) => ({
        date: new Date(dailyData.dateString),
        port: dailyData.port,
        appointments: dailyData.appointments_daily,
        sales: dailyData.sales_daily,
        conversion: dailyData.conversion_daily,
        totalOverall: dailyData.total_overall_daily,
        qualifiedAppointments: dailyData.qualified_appointments_daily,
        bookingSupplemental: dailyData.booking_supplemental_daily,
        bookingSelfService: dailyData.booking_selfService_daily,
        bookingOfficeNow: dailyData.booking_office_now_daily,
        bookingOfficeLater: dailyData.booking_office_later_daily,
        bookingRemoteNow: dailyData.booking_remote_now_daily,
        bookingRemoteLater: dailyData.booking_remote_later_daily,
        quotes: dailyData.quotes_daily,
        cards: dailyData.cards_daily,
        cardsApproved: dailyData.cards_approved_daily,
        cardsDenied: dailyData.cards_denied_daily,
        cardsTransferredToFolio: dailyData.cards_transferred_to_folio_daily,
        cardsPending: dailyData.cards_pending_daily,
        bookingsOffered: dailyData.bookings_offered_daily,
        bookingsConfirmed: dailyData.bookings_confirmed_daily,
        bookingsCanceled: dailyData.bookings_cancelled_daily,
        personalContribution: dailyData.personal_contribution_daily,
      })),
    })) ?? []

  const formattedMonthlyData =
    monthlyStatsData?.stats.map((item) => ({
      cruiseId: item.cruise_id,
      startDate: item.start_date,
      description: item.description,
      endDate: item.end_date,
      active: item.active,
      voyageNumber: item.voyage_number,
      voyageTarget: item.voyage_target,
      appointments: item.appointments,
      conversion: item.conversion,
      qualifiedAppointments: item.qualified_appointments,
      bookingStandardNow: item.bookings_standard_now,
      bookingStandardLater: item.bookings_standard_later,
      bookingStandard: item.bookings_standard,
      bookingSupplemental: item.bookings_supplemental,
      attainment: item.attainment,
      penetration: item.penetration,
      portStats: item.ports?.map((port: IPortStatResponse) => ({
        date: port.date,
        portName: port.port_name,
        appointments: port.appointments,
        totalOverall: port.total_overall,
        conversion: port.conversion,
        qualifiedAppointments: port.qualified_appointments,
        nonQualifiedAppointments: port.non_qualified_appointments,
        bookingStandard: port.bookings_standard,
        bookingSupplemental: port.bookings_supplemental,
        bookingSelfService: port.bookings_selfService,
        bookingStandardNow: port.booking_standard_now,
        bookingStandardLater: port.booking_standard_later,
        bookingRemoteNow: port.booking_remote_now,
        bookingRemoteLater: port.booking_remote_later,
      })),
    })) ?? []

  useEffect(() => {
    onSelectBox(selectedBox)
  }, [statsData])

  const dailyInfoAvailable =
    statsData && statsData?.stats[0]?.daily && statsData?.stats[0]?.daily.length > 0

  const getTotalCardsByStatus = (status?: string): number => {
    let totals: IAgentResponse | null
    if (isAgent) {
      totals =
        statsData && statsData.stats && statsData.stats.length > 0
          ? statsData.stats.find((agent) => agent.user_id === loggedUser?.userId)!
          : null
    } else {
      totals =
        statsData && statsData.stats && statsData.stats.length > 0
          ? statsData.stats[statsData.stats.length - 1]
          : null
    }
    let value = 0
    switch (status) {
      case EStatusCard.APPROVED:
        value = totals ? totals.cards_approved : 0
        break
      case EStatusCard.PENDING:
        value = totals ? totals.cards_pending : 0
        break
      case EStatusCard.DENIED:
        value = totals ? totals.cards_denied : 0
        break
      case 'total':
        value = totals ? totals.cards : 0
        break
      default:
        value = totals ? totals.cards_transferred_to_folio : 0
        break
    }

    return value
  }

  const onUpdatedStatusCard = async (response: IGenericResponse, newStatus: string) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    setSelectedBox(newStatus)
    await lazyGetStats()
  }

  const changeCardStatus = async (card: IGuestStats, status: EStatusCard) => {
    await updateCardStatus(
      {
        cardId: card.cardId,
        status,
      },
      onUpdatedStatusCard,
    )
  }

  const onUpdatedTransferredCard = async (response: IGenericResponse, transferred: boolean) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    if (transferred) {
      setSelectedBox('transferred')
    }
    await lazyGetStats()
  }

  const changeCardTransferred = async (card: IGuestStats, transferred: boolean) => {
    await updateCardTransferredToFolio(
      {
        cardId: card.cardId,
        transferredCard: transferred,
      },
      onUpdatedTransferredCard,
    )
  }

  const statsTable =
    statsData && statsData?.stats.length > 1 && dailyInfoAvailable ? (
      <AgentStatsTable stats={formattedData} />
    ) : (
      <Alert severity="info">No stats to display for this voyage.</Alert>
    )

  const statsTableMonthly =
    monthlyStatsData && monthlyStatsData?.stats.length > 0 ? (
      <CruiseStatsTable stats={formattedMonthlyData} />
    ) : (
      <Alert severity="info">No data has been recorded for the selected month.</Alert>
    )

  const penetrationAchieved = numeral(statsData?.penetration_achieved).format('0,0.0%')
  const actuals =
    (selectedTab === EStatsTab.VOYAGE
      ? statsData?.actuals
      : formattedMonthlyData.length > 0 &&
        formattedMonthlyData[formattedMonthlyData.length - 1].bookingStandard) || 0
  const goal =
    (selectedTab === EStatsTab.VOYAGE
      ? statsData?.voyage_target
      : formattedMonthlyData.length > 0 &&
        formattedMonthlyData[formattedMonthlyData.length - 1].voyageTarget) || 0
  const target = goal !== 0 ? actuals / goal : 0

  const bigNumbers = statsData &&
    !isLoading &&
    statsData.stats.length > 0 &&
    dailyInfoAvailable && (
      <Stack
        direction="row"
        justifyContent="space-between"
        columnGap={2}
        marginBottom={2}
        padding={1}
        overflow="auto">
        <Stack direction="row" justifyContent="space-between" columnGap={2}>
          <BigNumber
            key="big-number-1"
            label="BOOKING GOAL"
            formattedNumber={numeral(goal).format('0')}
            color={theme.palette.secondary.main}
          />
          <BigNumber
            key="big-number-2"
            label="ACTUALS"
            formattedNumber={numeral(actuals).format('0')}
            color={theme.palette.secondary.main}
          />
          <BigNumber
            key="big-number-3"
            label={selectedTab === EStatsTab.VOYAGE ? 'TARGET ACHIEVED' : 'ATTAINMENT'}
            formattedNumber={numeral(target).format('0,0%')}
            color={theme.palette.secondary.main}
          />
        </Stack>
        {selectedTab === EStatsTab.VOYAGE && (
          <Stack direction="row" justifyContent="space-between" columnGap={2}>
            <BigNumber
              key="big-number-4"
              label="CURRENT VOYAGE SAILED BOOKINGS"
              width={210}
              formattedNumber={numeral(statsData?.sailed_bookings).format('0')}
              color={theme.palette.custom.teal}
            />
            <BigNumber
              key="big-number-5"
              label="PENETRATION GOAL"
              formattedNumber={`${numeral(statsData?.penetration_goal).format('0')}%`}
              color={theme.palette.custom.teal}
            />
            <BigNumber
              key="big-number-6"
              label="PENETRATION ACHIEVED"
              formattedNumber={penetrationAchieved}
              color={theme.palette.custom.teal}
              style={{
                background: `linear-gradient(to right, ${theme.palette.custom.teal} ${penetrationAchieved}, transparent ${penetrationAchieved})`,
              }}
            />
          </Stack>
        )}
      </Stack>
    )

  const cardsBigNumbers = statsData &&
    !isLoading &&
    statsData.stats.length > 0 &&
    dailyInfoAvailable && (
      <Stack
        direction="row"
        justifyContent="flex-start"
        columnGap={2}
        marginBottom={2}
        padding={1}
        overflow="auto">
        <BigNumber
          key="big-number-10"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === 'offered' ? theme.palette.custom.backgroundMediumGray : '#fff',
          }}
          color={theme.palette.secondary.main}
          label="TOTAL APPLICATIONS"
          onSelectedBox={() => onSelectBox('offered')}
          formattedNumber={numeral(getTotalCardsByStatus('total')).format('0')}
        />
        <BigNumber
          key="big-number-7"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === EStatusCard.APPROVED
                ? theme.palette.custom.backgroundMediumGray
                : '#fff',
          }}
          color={theme.palette.secondary.main}
          label="APPROVED"
          onSelectedBox={() => onSelectBox(EStatusCard.APPROVED)}
          formattedNumber={numeral(getTotalCardsByStatus(EStatusCard.APPROVED)).format('0')}
        />
        <BigNumber
          key="big-number-8"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === EStatusCard.PENDING
                ? theme.palette.custom.backgroundMediumGray
                : '#fff',
          }}
          color={theme.palette.secondary.main}
          label="PENDING"
          onSelectedBox={() => onSelectBox(EStatusCard.PENDING)}
          formattedNumber={numeral(getTotalCardsByStatus(EStatusCard.PENDING)).format('0')}
        />
        <BigNumber
          key="big-number-9"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === EStatusCard.DENIED
                ? theme.palette.custom.backgroundMediumGray
                : '#fff',
          }}
          color={theme.palette.secondary.main}
          label="DENIED"
          onSelectedBox={() => onSelectBox(EStatusCard.DENIED)}
          formattedNumber={numeral(getTotalCardsByStatus(EStatusCard.DENIED)).format('0')}
        />
        <BigNumber
          key="big-number-11"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === 'transferred' ? theme.palette.custom.backgroundMediumGray : '#fff',
          }}
          color={theme.palette.secondary.main}
          label="TRANSFERRED TO FOLIO"
          onSelectedBox={() => onSelectBox('transferred')}
          formattedNumber={numeral(getTotalCardsByStatus('transferred')).format('0')}
        />
      </Stack>
    )

  let cardStatsTable

  if (cardStats && cardStats.length > 0) {
    switch (selectedBox) {
      case 'transferred':
      case EStatusCard.APPROVED:
      case EStatusCard.DENIED:
      case EStatusCard.PENDING:
        cardStatsTable = (
          <CardStatsByGuestTable
            stats={cardStats}
            handleOnChangeStatus={changeCardStatus}
            handleOnTransferred={changeCardTransferred}
          />
        )
        break
      default:
        cardStatsTable = statsData && statsData?.stats.length > 0 && dailyInfoAvailable && (
          <CardStatsByAgentTable stats={formattedData} />
        )
        break
    }
  } else {
    cardStatsTable = <Alert severity="info">No stats to display.</Alert>
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 2, marginBottom: 6 }}>
      <Wizard />
      {isActiveCruise && (
        <>
          <TabContext value={selectedTab}>
            <TabList
              sx={tabContainerStyle}
              onChange={(_event: React.SyntheticEvent, newTab: EStatsTab) => {
                setSelectedTab(newTab)
              }}>
              <Tab label="CURRENT VOYAGE" value={EStatsTab.VOYAGE} />
              <Tab label="MONTHLY" value={EStatsTab.MONTHLY} />
              <Tab label="CO-BRAND CREDIT CARD" value={EStatsTab.CARDS} />
            </TabList>
            <Paper elevation={5}>
              <TabPanel value={EStatsTab.VOYAGE}>
                {bigNumbers}
                {isLoading ? <GridSpinner /> : statsTable}
              </TabPanel>
            </Paper>
            <Paper elevation={5}>
              <TabPanel value={EStatsTab.MONTHLY}>
                <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                  <MonthYearSelector
                    initialValue={selectedDate || currentDate}
                    onChange={(values: IStatsPayload) => setSelectedDate(values)}
                  />
                </Box>
                {bigNumbers}
                {isLoading ? <GridSpinner /> : statsTableMonthly}
              </TabPanel>
            </Paper>
            <Paper elevation={5}>
              <TabPanel value={EStatsTab.CARDS}>
                {cardsBigNumbers}
                {isLoading ? <GridSpinner /> : cardStatsTable}
              </TabPanel>
            </Paper>
          </TabContext>
          <UpdateTargetForm
            key={`target-dialog-${isTargetOpen}`}
            isOpen={isTargetOpen}
            isLoading={isTargetLoading}
            initialValue={statsData?.voyage_target || 0}
            onAccept={selectedTab === EStatsTab.VOYAGE ? onAcceptTarget : onAcceptTargetMonthly}
            title={selectedTab === EStatsTab.MONTHLY ? 'Monthly Target' : 'Voyage Target'}
            onReject={() => {
              setIsTargetOpen(false)
            }}
          />
        </>
      )}
    </Container>
  )
}

export default Dashboard
