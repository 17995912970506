import EmailIcon from '@mui/icons-material/Email'
import LocationOn from '@mui/icons-material/LocationOn'
import InventoryIcon from '@mui/icons-material/Inventory'
import PeopleIcon from '@mui/icons-material/People'
import SailingIcon from '@mui/icons-material/Sailing'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import SettingsIcon from '@mui/icons-material/Settings'
import Divider from '@mui/material/Divider'
import { Box, List, ListItem, ListItemButton, Stack } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { ISettingsLayoutProps } from './types'
import { useAppSelector } from '../../redux/hooks'
import { ILoggedUser } from '../../redux/features/auth'
import Wizard from '../Wizard'

const DRAWER_WIDTH = 240

const standardItems = [
  {
    icon: <SettingsIcon />,
    text: 'Settings',
    to: '/settings',
  },
  {
    icon: <EmailIcon />,
    text: 'Mail Drop',
    to: '/settings/mail-drop',
  },
  {
    icon: <SailingIcon />,
    text: 'Voyages',
    to: '/settings/voyages',
  },
]

const superAdminItems = [
  {
    icon: <PeopleIcon />,
    text: 'Users',
    to: '/settings/users',
  },
  {
    icon: <LocationOn />,
    text: 'Ports',
    to: '/settings/ports',
  },
  {
    icon: <InventoryIcon />,
    text: 'Products & Vessels',
    to: '/settings/products-vessels',
  },
  {
    icon: <CurrencyExchangeIcon />,
    text: 'Conversion',
    to: '/settings/conversion',
  },
]

const SettingsLayout: React.FC<ISettingsLayoutProps> = ({ children }) => {
  const navigate = useNavigate()
  const loggedUser = useAppSelector((state) => state.auth.loggedUser) as ILoggedUser
  const location = useLocation()

  const drawer = (
    <Box sx={{ width: '97%' }}>
      <List>
        {standardItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.to)}
              selected={
                location.pathname === item.to || location.pathname.includes(item.to.split('/')[2])
              }>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {loggedUser && loggedUser.role === 'superadmin' && (
        <>
          <Divider />
          <List>
            {superAdminItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton
                  onClick={() => navigate(item.to)}
                  selected={location.pathname === item.to}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  )

  return (
    <Stack
      direction="row"
      flex={1}
      sx={{
        height: {
          xs: undefined,
          sm: 'auto',
          overflowX: 'auto',
        },
      }}>
      <Box
        component="nav"
        sx={{
          display: { xs: 'none', sm: 'flex' },
          width: DRAWER_WIDTH,
          flexShrink: { sm: 0 },
          minHeight: '100%',
          borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
        }}>
        {drawer}
      </Box>
      <Box
        component="div"
        sx={{
          p: 3,
          width: { xs: '100%', sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          background: (theme) => theme.palette.grey[100],
          overflow: 'auto',
        }}>
        <Box paddingBottom={2}>
          <Wizard />
        </Box>
        {children}
      </Box>
    </Stack>
  )
}

export default SettingsLayout
