import { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useNewVoyage } from './hook'
import BasicDataForm from '../../../../components/VoyageForm/BasicDataForm'
import MarketingInitiativesForm from '../../../../components/VoyageForm/MarketingInitiativeForm'
import {
  IBasicDataFormData,
  ICallbackTimeLimitsFormData,
  IItineraryFormData,
  INewVoyageOfficeHours,
  INewVoyageCallbackTimeLimits,
  INewVoyagePortInfo,
  IOfficeHoursFormData,
  IInitiative,
} from '../../../../components/VoyageForm/types'
import {
  ICallBackTimeCruisePayload,
  ICreateCruisePayload,
  INewVoyageData,
  IPortSequenceCruisePayload,
} from '../../../../types/cruises'
import ItineraryForm from '../../../../components/VoyageForm/ItineraryForm'
import OfficeHoursForm from '../../../../components/VoyageForm/OfficeHoursForm'
import CallbackTimeLimitsForm from '../../../../components/VoyageForm/CallbackTimeLimitsForm'
import GuestExtraData from '../../../../components/VoyageForm/UploadGuestExtraData'
import SettingsLayout from '../../../../components/SettingsLayout'
import { useVoyages } from '../hook'
import { IUploadFileForm } from '../../../../components/UploadFileForm/types'

const NewVoyage = () => {
  const { products, vessels, ports, isLoading, create } = useNewVoyage()
  const { refetch: refetchVoyages } = useVoyages()
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const [formData, setFormData] = useState<INewVoyageData>({
    vessel: 0,
    product: 0,
    name: '',
    target: 0,
    voyage_number: 0,
    sailed_bookings: 0,
    penetration_goal: 0,
    embarkPort: 0,
    embarkDate: '',
    embarkTime: '',
    debarkPort: 0,
    debarkDate: '',
    debarkTime: '',
    portSequence: [],
    officeHours: [],
    callbacks: [],
    files: [],
    initiatives: [],
  })

  const onSuccessCreateCruise = () => {
    refetchVoyages()
    navigate('/settings/voyages')
  }

  const onSubmitBasicDataForm = (data: IBasicDataFormData) => {
    const difference = moment(data.debarkDate).diff(moment(data.embarkDate), 'days')
    setFormData((oldData) => ({
      ...oldData,
      ...data,
    }))

    // Check if it's necessary to change the port sequence depending on whether embark or debark port data changed
    if (
      formData.portSequence.length === 0 ||
      (formData.portSequence.length > 0 &&
        (formData.portSequence[0].date === '' ||
          formData.portSequence[0].port !== data.embarkPort ||
          formData.portSequence[0].startTime !== data.embarkTime ||
          moment(formData.portSequence[0].date).format('MM/DD/YYYY') !==
            moment(data.embarkDate).format('MM/DD/YYYY') ||
          formData.portSequence[formData.portSequence.length - 1].port !== data.debarkPort ||
          formData.portSequence[formData.portSequence.length - 1].startTime !== data.debarkTime ||
          moment(formData.portSequence[formData.portSequence.length - 1].date).format(
            'MM/DD/YYYY',
          ) !== moment(data.debarkDate).format('MM/DD/YYYY')))
    ) {
      const itinerary: INewVoyagePortInfo[] = [
        {
          port: data.embarkPort,
          date: data.embarkDate,
          startTime: data.embarkTime,
          endTime: '',
        },
      ]

      for (let index = 0; index < difference - 1; index += 1) {
        itinerary.push({
          port: NaN,
          date: moment(data.embarkDate)
            .clone()
            .add(index + 1, 'day')
            .toString(),
          startTime: '',
          endTime: '',
          canModify: true,
        })
      }

      itinerary.push({
        port: data.debarkPort,
        date: data.debarkDate,
        startTime: data.debarkTime,
        endTime: '',
      })
      setFormData((oldData) => ({
        ...oldData,
        portSequence: itinerary,
      }))
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitItineraryForm = (data: IItineraryFormData) => {
    const { portSequence } = data
    setFormData((oldData) => ({ ...oldData, portSequence }))

    // Set the office hours data when there is no modified information
    if (
      formData.officeHours.length === 0 ||
      (formData.officeHours.length > 0 && formData.officeHours[0].hours[0].openHour === '')
    ) {
      const officeHours: INewVoyageOfficeHours[] = portSequence.map((port) => ({
        date: port.date,
        port: port.port,
        hours: [
          {
            openHour: '',
            closeHour: '',
          },
        ],
      }))
      setFormData((oldData) => ({ ...oldData!, officeHours }))
    }
    // Set new office hours when ports changed any data
    else if (
      (formData.officeHours.length > 2 &&
        !formData.officeHours.every(
          (officeHour, index) => officeHour.port === portSequence[index].port,
        )) ||
      formData.officeHours.length !== portSequence.length
    ) {
      const officeHours: INewVoyageOfficeHours[] = portSequence.map((port, index) => {
        if (index === 0 && formData.officeHours[0].port === portSequence[0].port) {
          return formData.officeHours[0]
        }

        if (
          index === portSequence.length - 1 &&
          formData.officeHours[formData.officeHours.length - 1].port ===
            portSequence[portSequence.length - 1].port
        ) {
          return formData.officeHours[formData.officeHours.length - 1]
        }

        return {
          date: port.date,
          port: port.port,
          hours: [
            {
              openHour: '',
              closeHour: '',
            },
          ],
        }
      })
      setFormData((oldData) => ({ ...oldData!, officeHours }))
    }

    if (
      formData.callbacks.length === 0 ||
      (formData.callbacks.length > 0 &&
        (formData.callbacks[0].hours[0].openHour === '' ||
          formData.callbacks[0].port !== portSequence[0].port))
    ) {
      const callbacks: INewVoyageCallbackTimeLimits[] = portSequence.map((port) => ({
        date: port.date,
        port: port.port,
        hours: [
          {
            openHour: '',
            closeHour: '',
            callbacksPerHour: 0,
          },
        ],
      }))
      setFormData((oldData) => ({ ...oldData!, callbacks }))
    } else if (
      (formData.callbacks.length > 2 &&
        !formData.callbacks.every(
          (officeHour, index) => officeHour.port === portSequence[index].port,
        )) ||
      formData.callbacks.length !== portSequence.length
    ) {
      const callbacks: INewVoyageCallbackTimeLimits[] = portSequence.map((port, index) => {
        if (index === 0 && formData.callbacks[0].port === portSequence[0].port) {
          return formData.callbacks[0]
        }

        if (
          index === portSequence.length - 1 &&
          formData.callbacks[formData.callbacks.length - 1].port ===
            portSequence[portSequence.length - 1].port
        ) {
          return formData.callbacks[formData.callbacks.length - 1]
        }

        return {
          date: port.date,
          port: port.port,
          hours: [
            {
              openHour: '',
              closeHour: '',
              callbacksPerHour: 0,
            },
          ],
        }
      })
      setFormData((oldData) => ({ ...oldData!, callbacks }))
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitOfficeHoursForm = (data: IOfficeHoursFormData) => {
    const { officeHours } = data
    setFormData((oldData) => ({ ...oldData!, officeHours }))
    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitCallbackTimeLimitsForm = (data: ICallbackTimeLimitsFormData) => {
    const { callbacks } = data
    setFormData((oldData) => ({ ...oldData!, callbacks }))

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onUploadGuestExtraDataForm = (values: IUploadFileForm) => {
    if (values && values.file) {
      const newFiles = [...formData.files]
      newFiles.push({
        file: [values.file[0]],
        name: values.name,
      })
      setFormData((oldData) => ({
        ...oldData!,
        files: newFiles,
      }))
    }
  }

  const onSubmitGuestExtraDataForm = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const removeFileInFormData = (name: string) => {
    const newFiles = [...formData.files]
    const selectedFileIndex = newFiles.findIndex((file) => file.name === name)
    if (selectedFileIndex !== -1) {
      newFiles[selectedFileIndex] = {
        ...newFiles[selectedFileIndex],
        deleted: true,
      }
    }

    setFormData((oldData) => ({
      ...oldData!,
      files: newFiles,
    }))
  }

  const onAddNewInitiative = (theInitiative: IInitiative) => {
    const newInitiatives = [...formData.initiatives]

    if (theInitiative.deleted) {
      const initiativeDeletedIndex = newInitiatives.findIndex(
        (item) => item.id === theInitiative.initiative_id,
      )
      if (initiativeDeletedIndex !== -1) {
        newInitiatives[initiativeDeletedIndex] = {
          id: theInitiative.initiative_id,
          type: theInitiative.initiative_type,
          productName: theInitiative.product,
          productId: theInitiative.product_id,
          shipId: Number(theInitiative.ship_id),
          shipName: theInitiative.ship_name,
          date: theInitiative.date,
          deleted: true,
        }
      }
    } else {
      newInitiatives.push({
        id: theInitiative.initiative_id || -1 * (Math.random() * (1000 - 1) + 1),
        type: theInitiative.initiative_type,
        productName: theInitiative.product,
        productId: theInitiative.product_id,
        shipId: Number(theInitiative.ship_id),
        shipName: theInitiative.ship_name,
        date: theInitiative.date,
      })
    }

    setFormData((oldData) => ({
      ...oldData!,
      initiatives: newInitiatives,
    }))
  }

  const onSubmitForm = async () => {
    const portSequence: IPortSequenceCruisePayload[] = formData.portSequence.map((port) => ({
      port_id: port.port.toString(),
      arrival_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.startTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      departure_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.endTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
    }))

    const officeHours: ICallBackTimeCruisePayload[] = []

    formData.officeHours.forEach((hour) => {
      hour.hours.forEach((internalHour) => {
        officeHours.push({
          date: moment(hour.date).format('YYYY-MM-DD'),
          open_hour: moment(internalHour.openHour, 'HH:mm').format('hh:mm A'),
          close_hour: moment(internalHour.closeHour, 'HH:mm').format('hh:mm A'),
        })
      })
    })

    const callbacksPerHour: ICallBackTimeCruisePayload[] = []
    formData.callbacks.forEach((callback) => {
      callback.hours.forEach((hour) => {
        callbacksPerHour.push({
          callbacksPerHour: hour.callbacksPerHour,
          close_hour: hour.closeHour,
          date: moment(callback.date).format('YYYY-MM-DD'),
          open_hour: hour.openHour,
        })
      })
    })

    const theInitiatives = formData.initiatives
      .filter((item) => !item.deleted)
      .map((item) => ({
        id: item.id && item.id < 0 ? undefined : item.id || undefined,
        type: item.type,
        productId: Number(item.productId),
        shipId: Number(item.shipId),
        date: item.date,
        deleted: item.deleted || false,
      }))

    const newFormData = new FormData()
    const payload: ICreateCruisePayload = {
      ship_id: formData.vessel.toString(),
      destination_id: formData.product.toString(),
      description: formData.name,
      voyage_target: formData.target,
      start_port: formData.embarkPort.toString(),
      end_port: formData.debarkPort.toString(),
      voyage_number: formData.voyage_number,
      penetration_goal: formData.penetration_goal,
      sailed_bookings: formData.sailed_bookings,
      start_date: `${moment(formData.embarkDate).format('YYYY-MM-DD')} ${moment(
        formData.embarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      end_date: `${moment(formData.debarkDate).format('YYYY-MM-DD')} ${moment(
        formData.debarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      port_sequence: portSequence,
      office_hours: officeHours,
      callback_times: callbacksPerHour,
      initiatives: theInitiatives,
    }
    const jsonData = JSON.stringify(payload)
    newFormData.append('data', jsonData)

    formData.files
      .filter((item) => item.file && !item.id && !item.deleted)
      .forEach((item) => {
        newFormData.append('files', new File([item.file![0]], item.name))
      })

    await create(newFormData, onSuccessCreateCruise)
  }

  const goBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const steps = [
    {
      label: 'Enter basic data for voyage',
      content: (
        <BasicDataForm
          products={products ?? []}
          vessels={vessels ?? []}
          ports={ports ?? []}
          values={{
            vessel: formData.vessel,
            product: formData.product,
            name: formData.name,
            target: formData.target,
            voyage_number: formData.voyage_number,
            sailed_bookings: formData.sailed_bookings,
            penetration_goal: formData.penetration_goal ?? 0,
            embarkPort: formData.embarkPort,
            embarkDate: formData.embarkDate,
            embarkTime: formData.embarkTime,
            debarkPort: formData.debarkPort,
            debarkDate: formData.debarkDate,
            debarkTime: formData.debarkTime,
          }}
          onSubmit={onSubmitBasicDataForm}
        />
      ),
    },
    {
      label: 'Define itinerary',
      content: (
        <ItineraryForm
          ports={ports ?? []}
          itinerary={formData?.portSequence ?? []}
          goBack={goBack}
          onSubmit={onSubmitItineraryForm}
        />
      ),
    },
    {
      label: 'Set daily office hours',
      content: (
        <OfficeHoursForm
          ports={ports ?? []}
          officeHours={formData?.officeHours ?? []}
          onSubmit={onSubmitOfficeHoursForm}
          goBack={goBack}
        />
      ),
    },
    {
      label: 'Set the call backs - appointments',
      content: (
        <CallbackTimeLimitsForm
          ports={ports ?? []}
          callbacks={formData?.callbacks ?? []}
          onSubmit={onSubmitCallbackTimeLimitsForm}
          setStateCallbacks={setFormData}
          goBack={goBack}
        />
      ),
    },
    {
      label: 'Upload guest extra data',
      content: (
        <GuestExtraData
          files={formData?.files ?? []}
          onRemoveFile={removeFileInFormData}
          onUploadFile={onUploadGuestExtraDataForm}
          onSubmit={onSubmitGuestExtraDataForm}
          goBack={goBack}
        />
      ),
    },
    {
      label: 'Set Marketing Initiatives',
      content: (
        <MarketingInitiativesForm
          products={products || []}
          vessels={vessels || []}
          initiatives={formData?.initiatives ?? []}
          onSubmit={onSubmitForm}
          onAddNewInitiative={onAddNewInitiative}
          goBack={goBack}
          isLoading={isLoading}
          embarkDate={formData.embarkDate}
          debarkDate={formData.debarkDate}
        />
      ),
    },
  ]

  return (
    <SettingsLayout>
      <Container maxWidth={false}>
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          New Voyage
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }>
                {step.label}
              </StepLabel>
              <StepContent>{step.content}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Container>
    </SettingsLayout>
  )
}

export default NewVoyage
